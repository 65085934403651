// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-business-update-faq-jsx": () => import("./../../../src/pages/about/business-update-faq.jsx" /* webpackChunkName: "component---src-pages-about-business-update-faq-jsx" */),
  "component---src-pages-about-community-fringe-world-festival-jsx": () => import("./../../../src/pages/about/community/fringe-world-festival.jsx" /* webpackChunkName: "component---src-pages-about-community-fringe-world-festival-jsx" */),
  "component---src-pages-about-community-telethon-community-cinemas-jsx": () => import("./../../../src/pages/about/community/telethon-community-cinemas.jsx" /* webpackChunkName: "component---src-pages-about-community-telethon-community-cinemas-jsx" */),
  "component---src-pages-about-index-jsx": () => import("./../../../src/pages/about/index.jsx" /* webpackChunkName: "component---src-pages-about-index-jsx" */),
  "component---src-pages-about-people-careers-jsx": () => import("./../../../src/pages/about/people/careers.jsx" /* webpackChunkName: "component---src-pages-about-people-careers-jsx" */),
  "component---src-pages-about-people-index-jsx": () => import("./../../../src/pages/about/people/index.jsx" /* webpackChunkName: "component---src-pages-about-people-index-jsx" */),
  "component---src-pages-about-sustainability-carbon-offset-jsx": () => import("./../../../src/pages/about/sustainability/carbon-offset.jsx" /* webpackChunkName: "component---src-pages-about-sustainability-carbon-offset-jsx" */),
  "component---src-pages-about-sustainability-index-jsx": () => import("./../../../src/pages/about/sustainability/index.jsx" /* webpackChunkName: "component---src-pages-about-sustainability-index-jsx" */),
  "component---src-pages-business-docusign-support-jsx": () => import("./../../../src/pages/business/docusign-support.jsx" /* webpackChunkName: "component---src-pages-business-docusign-support-jsx" */),
  "component---src-pages-business-electricity-contract-update-nsfc-tsx": () => import("./../../../src/pages/business/electricity/contract-update-nsfc.tsx" /* webpackChunkName: "component---src-pages-business-electricity-contract-update-nsfc-tsx" */),
  "component---src-pages-business-electricity-contract-update-tsx": () => import("./../../../src/pages/business/electricity/contract-update.tsx" /* webpackChunkName: "component---src-pages-business-electricity-contract-update-tsx" */),
  "component---src-pages-business-index-jsx": () => import("./../../../src/pages/business/index.jsx" /* webpackChunkName: "component---src-pages-business-index-jsx" */),
  "component---src-pages-contract-update-nsfc-tsx": () => import("./../../../src/pages/contract-update-nsfc.tsx" /* webpackChunkName: "component---src-pages-contract-update-nsfc-tsx" */),
  "component---src-pages-contract-update-tsx": () => import("./../../../src/pages/contract-update.tsx" /* webpackChunkName: "component---src-pages-contract-update-tsx" */),
  "component---src-pages-dynamicsforms-index-2-jsx": () => import("./../../../src/pages/dynamicsforms/index2.jsx" /* webpackChunkName: "component---src-pages-dynamicsforms-index-2-jsx" */),
  "component---src-pages-dynamicsforms-index-3-jsx": () => import("./../../../src/pages/dynamicsforms/index3.jsx" /* webpackChunkName: "component---src-pages-dynamicsforms-index-3-jsx" */),
  "component---src-pages-dynamicsforms-index-4-jsx": () => import("./../../../src/pages/dynamicsforms/index4.jsx" /* webpackChunkName: "component---src-pages-dynamicsforms-index-4-jsx" */),
  "component---src-pages-dynamicsforms-index-5-jsx": () => import("./../../../src/pages/dynamicsforms/index5.jsx" /* webpackChunkName: "component---src-pages-dynamicsforms-index-5-jsx" */),
  "component---src-pages-dynamicsforms-index-jsx": () => import("./../../../src/pages/dynamicsforms/index.jsx" /* webpackChunkName: "component---src-pages-dynamicsforms-index-jsx" */),
  "component---src-pages-flybuys-10-x-offer-jsx": () => import("./../../../src/pages/flybuys-10x-offer.jsx" /* webpackChunkName: "component---src-pages-flybuys-10-x-offer-jsx" */),
  "component---src-pages-flybuys-20-x-offer-jsx": () => import("./../../../src/pages/flybuys-20x-offer.jsx" /* webpackChunkName: "component---src-pages-flybuys-20-x-offer-jsx" */),
  "component---src-pages-flybuys-offer-confirmation-jsx": () => import("./../../../src/pages/flybuys/offer-confirmation.jsx" /* webpackChunkName: "component---src-pages-flybuys-offer-confirmation-jsx" */),
  "component---src-pages-flybuys-tsx": () => import("./../../../src/pages/flybuys.tsx" /* webpackChunkName: "component---src-pages-flybuys-tsx" */),
  "component---src-pages-flybuys-win-jsx": () => import("./../../../src/pages/flybuys-win.jsx" /* webpackChunkName: "component---src-pages-flybuys-win-jsx" */),
  "component---src-pages-header-footer-js": () => import("./../../../src/pages/header-footer.js" /* webpackChunkName: "component---src-pages-header-footer-js" */),
  "component---src-pages-hello-js": () => import("./../../../src/pages/hello.js" /* webpackChunkName: "component---src-pages-hello-js" */),
  "component---src-pages-homepage-jsx": () => import("./../../../src/pages/homepage.jsx" /* webpackChunkName: "component---src-pages-homepage-jsx" */),
  "component---src-pages-homepage-new-jsx": () => import("./../../../src/pages/homepage-new.jsx" /* webpackChunkName: "component---src-pages-homepage-new-jsx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kwik-gas-jsx": () => import("./../../../src/pages/kwik-gas.jsx" /* webpackChunkName: "component---src-pages-kwik-gas-jsx" */),
  "component---src-pages-lpg-jsx": () => import("./../../../src/pages/lpg.jsx" /* webpackChunkName: "component---src-pages-lpg-jsx" */),
  "component---src-pages-moving-house-jsx": () => import("./../../../src/pages/moving-house.jsx" /* webpackChunkName: "component---src-pages-moving-house-jsx" */),
  "component---src-pages-ms-sample-js": () => import("./../../../src/pages/ms-sample.js" /* webpackChunkName: "component---src-pages-ms-sample-js" */),
  "component---src-pages-natural-gas-jsx": () => import("./../../../src/pages/natural-gas.jsx" /* webpackChunkName: "component---src-pages-natural-gas-jsx" */),
  "component---src-pages-rac-50-jsx": () => import("./../../../src/pages/rac50.jsx" /* webpackChunkName: "component---src-pages-rac-50-jsx" */),
  "component---src-pages-rac-index-jsx": () => import("./../../../src/pages/rac/index.jsx" /* webpackChunkName: "component---src-pages-rac-index-jsx" */),
  "component---src-pages-rac-lpg-jsx": () => import("./../../../src/pages/rac/lpg.jsx" /* webpackChunkName: "component---src-pages-rac-lpg-jsx" */),
  "component---src-pages-rac-natural-gas-jsx": () => import("./../../../src/pages/rac/natural-gas.jsx" /* webpackChunkName: "component---src-pages-rac-natural-gas-jsx" */),
  "component---src-pages-recipes-js": () => import("./../../../src/pages/recipes.js" /* webpackChunkName: "component---src-pages-recipes-js" */),
  "component---src-pages-service-availability-jsx": () => import("./../../../src/pages/service-availability.jsx" /* webpackChunkName: "component---src-pages-service-availability-jsx" */),
  "component---src-pages-thinking-gas-jsx": () => import("./../../../src/pages/thinking-gas.jsx" /* webpackChunkName: "component---src-pages-thinking-gas-jsx" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-pages-waseniors-jsx": () => import("./../../../src/pages/waseniors.jsx" /* webpackChunkName: "component---src-pages-waseniors-jsx" */),
  "component---src-templates-employee-discount-offer-jsx": () => import("./../../../src/templates/employee-discount-offer.jsx" /* webpackChunkName: "component---src-templates-employee-discount-offer-jsx" */),
  "component---src-templates-family-friends-offer-jsx": () => import("./../../../src/templates/family-friends-offer.jsx" /* webpackChunkName: "component---src-templates-family-friends-offer-jsx" */)
}

